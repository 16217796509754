<template>
  <svg
    viewBox="0 0 16 17"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
  >
    <path
      d="M2.72991 7.13846C2.68874 7.03917 2.66602 6.9303 2.66602 6.81612C2.66602 6.70193 2.68874 6.59306 2.72991 6.49377C2.77101 6.39445 2.83192 6.3014 2.91266 6.22066L5.71968 3.41364C6.04854 3.08478 6.58173 3.08478 6.9106 3.41364C7.23946 3.7425 7.23946 4.27569 6.9106 4.60456L5.54114 5.97401L10.245 5.97401C11.0639 5.97401 11.8493 6.29932 12.4283 6.87838C13.0074 7.45744 13.3327 8.24282 13.3327 9.06173L13.3327 12.9916C13.3327 13.4566 12.9557 13.8337 12.4906 13.8337C12.0255 13.8337 11.6485 13.4566 11.6485 12.9916L11.6485 9.06173C11.6485 8.6895 11.5006 8.33251 11.2374 8.0693C10.9742 7.80609 10.6172 7.65822 10.245 7.65822L5.54114 7.65822L6.9106 9.02767C7.23946 9.35654 7.23946 9.88973 6.9106 10.2186C6.58173 10.5475 6.04854 10.5475 5.71968 10.2186L2.91266 7.41157C2.83192 7.33084 2.77101 7.23778 2.72991 7.13846Z"
    />
  </svg>
</template>
